/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
@keyframes typing-debounce {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #9b6ca7 }
}
