html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.card-shadow-dark {
  background: linear-gradient(145deg, #13161e, #161a23);
  box-shadow:  20px 20px 60px #12141c,
  -20px -20px 60px #181c26 !important;
}

.card-shadow-light {
  background: linear-gradient(145deg, #d5d8dd, #feffff);
  box-shadow:  20px 20px 60px #c9ccd0,
  -20px -20px 60px #ffffff !important;
}



@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
