#stripe-checkout-form #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#stripe-checkout-form #payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
#stripe-checkout-form button {
  transition: all 0.2s ease;
}

#stripe-checkout-form button:hover {
  filter: contrast(115%);
}

#stripe-checkout-form button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
#stripe-checkout-form .spinner,
#stripe-checkout-form .spinner:before,
#stripe-checkout-form .spinner:after {
  border-radius: 50%;
}

#stripe-checkout-form .spinner {
  color: #994cad;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

#stripe-checkout-form .spinner:before,
#stripe-checkout-form .spinner:after {
  position: absolute;
  content: '';
}

#stripe-checkout-form .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #994cad;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

#stripe-checkout-form .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #994cad;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  #stripe-checkout-form form {
    width: 80vw;
    min-width: initial;
  }
}
